import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_4eb71d14 from 'nuxt_plugin_plugin_4eb71d14' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_recaptcha_1639b0da from 'nuxt_plugin_recaptcha_1639b0da' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_nuxtplugin0fd64a85_bbf5dfdc from 'nuxt_plugin_nuxtplugin0fd64a85_bbf5dfdc' // Source: ./nuxt.plugin.0fd64a85.js (mode: 'server')
import nuxt_plugin_pluginserver_79659292 from 'nuxt_plugin_pluginserver_79659292' // Source: ./nuxt-speedkit/plugin.server.js (mode: 'server')
import nuxt_plugin_pluginclient_13ac210a from 'nuxt_plugin_pluginclient_13ac210a' // Source: ./nuxt-speedkit/plugin.client.js (mode: 'client')
import nuxt_plugin_gtm_178bea0c from 'nuxt_plugin_gtm_178bea0c' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_nuxtgooglemaps_a8dee99a from 'nuxt_plugin_nuxtgooglemaps_a8dee99a' // Source: ./nuxt-google-maps.js (mode: 'all')
import nuxt_plugin_vuescrollto_5daa2a65 from 'nuxt_plugin_vuescrollto_5daa2a65' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_8240ba68 from 'nuxt_plugin_cookieuniversalnuxt_8240ba68' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_26a0cf97 from 'nuxt_plugin_pluginutils_26a0cf97' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_90a859bc from 'nuxt_plugin_pluginrouting_90a859bc' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_1898bb8d from 'nuxt_plugin_pluginmain_1898bb8d' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_distplugin5ec64c9a_9255f790 from 'nuxt_plugin_distplugin5ec64c9a_9255f790' // Source: ./dist.plugin.5ec64c9a.mjs (mode: 'all')
import nuxt_plugin_image_19ffc81f from 'nuxt_plugin_image_19ffc81f' // Source: ./image.js (mode: 'all')
import nuxt_plugin_corepluginb85fa712_3b8d6ec0 from 'nuxt_plugin_corepluginb85fa712_3b8d6ec0' // Source: ./core.plugin.b85fa712.ts (mode: 'all')
import nuxt_plugin_pluginsssr5dd01400_7caff80c from 'nuxt_plugin_pluginsssr5dd01400_7caff80c' // Source: ./plugins.ssr.5dd01400.js (mode: 'all')
import nuxt_plugin_deviceplugin_2d9602a2 from 'nuxt_plugin_deviceplugin_2d9602a2' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_5479f180 from 'nuxt_plugin_workbox_5479f180' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_14410e53 from 'nuxt_plugin_metaplugin_14410e53' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_18e8b3c7 from 'nuxt_plugin_iconplugin_18e8b3c7' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_tokenexpired_ca1e20ca from 'nuxt_plugin_tokenexpired_ca1e20ca' // Source: ../plugins/token-expired (mode: 'all')
import nuxt_plugin_cartexpired_67870729 from 'nuxt_plugin_cartexpired_67870729' // Source: ../plugins/palmers/cart-expired (mode: 'all')
import nuxt_plugin_storeConfigPlugin_234467e0 from 'nuxt_plugin_storeConfigPlugin_234467e0' // Source: ../plugins/storeConfigPlugin (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_fcPlugin_5a846bc6 from 'nuxt_plugin_fcPlugin_5a846bc6' // Source: ../plugins/fcPlugin (mode: 'all')
import nuxt_plugin_vimeoplayer_53381c3b from 'nuxt_plugin_vimeoplayer_53381c3b' // Source: ../plugins/palmers/vimeo-player (mode: 'all')
import nuxt_plugin_youtubeplayer_baa1d388 from 'nuxt_plugin_youtubeplayer_baa1d388' // Source: ../plugins/palmers/youtube-player (mode: 'all')
import nuxt_plugin_meta_06739bf0 from 'nuxt_plugin_meta_06739bf0' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"@vue-storefront\u002Fmagento-theme","meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=5, user-scalable=5"},{"hid":"description","name":"description","content":"Magento2 Integration for Vue Storefront 2"},{"name":"generator","content":"Vue Storefront 2"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"preconnect","href":"https:\u002F\u002Fwww.google-analytics.com"},{"rel":"preconnect","href":"https:\u002F\u002Fwww.facebook.com"},{"rel":"preconnect","href":"https:\u002F\u002Fconnect.facebook.net"},{"rel":"preconnect","href":"https:\u002F\u002Fapi.bounce-commerce.de"},{"rel":"preconnect","href":"https:\u002F\u002Fwww.dwin1.com"},{"rel":"preconnect","href":"https:\u002F\u002Fcookie-cdn.cookiepro.com"},{"rel":"preconnect","href":"https:\u002F\u002Fgeolocation.onetrust.com"},{"rel":"preconnect","href":"https:\u002F\u002Fwww.googletagmanager.com"}],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_4eb71d14 === 'function') {
    await nuxt_plugin_plugin_4eb71d14(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_1639b0da === 'function') {
    await nuxt_plugin_recaptcha_1639b0da(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin0fd64a85_bbf5dfdc === 'function') {
    await nuxt_plugin_nuxtplugin0fd64a85_bbf5dfdc(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_79659292 === 'function') {
    await nuxt_plugin_pluginserver_79659292(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_13ac210a === 'function') {
    await nuxt_plugin_pluginclient_13ac210a(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_178bea0c === 'function') {
    await nuxt_plugin_gtm_178bea0c(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtgooglemaps_a8dee99a === 'function') {
    await nuxt_plugin_nuxtgooglemaps_a8dee99a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_5daa2a65 === 'function') {
    await nuxt_plugin_vuescrollto_5daa2a65(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_8240ba68 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_8240ba68(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_26a0cf97 === 'function') {
    await nuxt_plugin_pluginutils_26a0cf97(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_90a859bc === 'function') {
    await nuxt_plugin_pluginrouting_90a859bc(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_1898bb8d === 'function') {
    await nuxt_plugin_pluginmain_1898bb8d(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin5ec64c9a_9255f790 === 'function') {
    await nuxt_plugin_distplugin5ec64c9a_9255f790(app.context, inject)
  }

  if (typeof nuxt_plugin_image_19ffc81f === 'function') {
    await nuxt_plugin_image_19ffc81f(app.context, inject)
  }

  if (typeof nuxt_plugin_corepluginb85fa712_3b8d6ec0 === 'function') {
    await nuxt_plugin_corepluginb85fa712_3b8d6ec0(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsssr5dd01400_7caff80c === 'function') {
    await nuxt_plugin_pluginsssr5dd01400_7caff80c(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_2d9602a2 === 'function') {
    await nuxt_plugin_deviceplugin_2d9602a2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_5479f180 === 'function') {
    await nuxt_plugin_workbox_5479f180(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_14410e53 === 'function') {
    await nuxt_plugin_metaplugin_14410e53(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_18e8b3c7 === 'function') {
    await nuxt_plugin_iconplugin_18e8b3c7(app.context, inject)
  }

  if (typeof nuxt_plugin_tokenexpired_ca1e20ca === 'function') {
    await nuxt_plugin_tokenexpired_ca1e20ca(app.context, inject)
  }

  if (typeof nuxt_plugin_cartexpired_67870729 === 'function') {
    await nuxt_plugin_cartexpired_67870729(app.context, inject)
  }

  if (typeof nuxt_plugin_storeConfigPlugin_234467e0 === 'function') {
    await nuxt_plugin_storeConfigPlugin_234467e0(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_fcPlugin_5a846bc6 === 'function') {
    await nuxt_plugin_fcPlugin_5a846bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_vimeoplayer_53381c3b === 'function') {
    await nuxt_plugin_vimeoplayer_53381c3b(app.context, inject)
  }

  if (typeof nuxt_plugin_youtubeplayer_baa1d388 === 'function') {
    await nuxt_plugin_youtubeplayer_baa1d388(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_06739bf0 === 'function') {
    await nuxt_plugin_meta_06739bf0(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
