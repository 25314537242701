const middleware = {}

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['palmers/affiliate'] = require('../middleware/palmers/affiliate.ts')
middleware['palmers/affiliate'] = middleware['palmers/affiliate'].default || middleware['palmers/affiliate']

middleware['palmers/create-cart'] = require('../middleware/palmers/create-cart.ts')
middleware['palmers/create-cart'] = middleware['palmers/create-cart'].default || middleware['palmers/create-cart']

middleware['palmers/is-search-value'] = require('../middleware/palmers/is-search-value.ts')
middleware['palmers/is-search-value'] = middleware['palmers/is-search-value'].default || middleware['palmers/is-search-value']

middleware['palmers/set-session-id'] = require('../middleware/palmers/set-session-id.ts')
middleware['palmers/set-session-id'] = middleware['palmers/set-session-id'].default || middleware['palmers/set-session-id']

middleware['palmers/sso-token'] = require('../middleware/palmers/sso-token.ts')
middleware['palmers/sso-token'] = middleware['palmers/sso-token'].default || middleware['palmers/sso-token']

export default middleware
