import Vue from 'vue'
import { createImage} from '~image'
import NuxtImg from '~image/components/nuxt-img.vue'
import NuxtPicture from '~image/components/nuxt-picture.vue'

import * as staticRuntime$6850 from '/pal-fe/node_modules/@nuxt/image/dist/runtime/providers/static.js'
import * as imgproxyRuntime$ff82 from '/pal-fe/modules/palmers/providers/imgproxy.js'
import * as cloudfrontRuntime$f32b from '/pal-fe/modules/palmers/providers/cloudfront.js'

const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgproxy",
  "domains": [],
  "alias": {}
}

imageOptions.providers = {
  ['static']: { provider: staticRuntime$6850, defaults: {} },
  ['imgproxy']: { provider: imgproxyRuntime$ff82, defaults: {"baseURL":"https://img.palmers.at/","key":"9aea2bed7f14bbc14efb5b1b0df5884d213938c72f8df1cbe1b72b1c7fc2e4a780ea46cb58ba6c6b9b09be64851b58233321af66576130e35714a3c5b887cbbb","salt":"d7ee681b6ee6e537d0b0c3b27fc636a33bee9b9c4d20df2ec9a4d7559f31d23a5b92cc2f1fca318ff4ff13b387f6f57f7bb5995901e0b54229fb512fa3988738"} },
  ['cloudfront']: { provider: cloudfrontRuntime$f32b, defaults: undefined }
}

Vue.component(NuxtImg.name, NuxtImg)
Vue.component(NuxtPicture.name, NuxtPicture)
Vue.component('NImg', NuxtImg)
Vue.component('NPicture', NuxtPicture)

export default function (nuxtContext, inject) {
  const $img = createImage(imageOptions, nuxtContext)

  if (process.static && process.server) {
    nuxtContext.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._img = nuxtState._img || {}
    })
  }

  inject('img', $img)
}
