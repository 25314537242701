import locale77304fa0 from '../../lang/de.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {"fallbackLocale":"palmers_de","numberFormats":{"palmers_de":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"gifts_de":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"palmers_de","file":"de.js","iso":"de_DE","defaultCurrency":"EUR","domain":"www.palmers.at"},{"code":"gifts_de","file":"de.js","iso":"de_DE","defaultCurrency":"EUR","domain":"gifts.palmers.at"}],
  defaultLocale: "palmers_de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/pal-fe/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: true,
  seo: false,
  baseUrl: "https://www.palmers.at/",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "DE",
  normalizedLocales: [{"code":"palmers_de","file":"de.js","iso":"de_DE","defaultCurrency":"EUR","domain":"www.palmers.at"},{"code":"gifts_de","file":"de.js","iso":"de_DE","defaultCurrency":"EUR","domain":"gifts.palmers.at"}],
  localeCodes: ["palmers_de","gifts_de"],
}

export const localeMessages = {
  'de.js': () => Promise.resolve(locale77304fa0),
}
