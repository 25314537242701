import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _faa587f0 = () => interopDefault(import('../pages/_Palmers/Checkout.vue' /* webpackChunkName: "" */))
const _0f4daf91 = () => interopDefault(import('../pages/_Palmers/Checkout/OrderError.vue' /* webpackChunkName: "" */))
const _7b138f5f = () => interopDefault(import('../pages/_Palmers/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _bc591bcc = () => interopDefault(import('../pages/_Palmers/Checkout/Register.vue' /* webpackChunkName: "" */))
const _544ab37d = () => interopDefault(import('../pages/_Palmers/Checkout/ShippingMethods.vue' /* webpackChunkName: "" */))
const _2044b8b6 = () => interopDefault(import('../pages/_Palmers/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _4d292168 = () => interopDefault(import('../pages/_Palmers/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _c5249a8a = () => interopDefault(import('../pages/_Palmers/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _11f018c1 = () => interopDefault(import('../pages/_Palmers/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _3c213f0b = () => interopDefault(import('../pages/_Palmers/MyAccount/Account/Account.vue' /* webpackChunkName: "" */))
const _78ad65a2 = () => interopDefault(import('../pages/_Palmers/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _603d259f = () => interopDefault(import('../pages/_Palmers/MyAccount/Faq/CustomerFaq.vue' /* webpackChunkName: "" */))
const _7794bd86 = () => interopDefault(import('../pages/_Palmers/MyAccount/Loyalty/Loyalty.vue' /* webpackChunkName: "" */))
const _f0813b22 = () => interopDefault(import('../pages/_Palmers/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _6bb21c4f = () => interopDefault(import('../pages/_Palmers/MyAccount/Overview/Overview.vue' /* webpackChunkName: "" */))
const _0668f5b8 = () => interopDefault(import('../pages/_Palmers/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _01d10015 = () => interopDefault(import('../pages/_Palmers/StoreFinder.vue' /* webpackChunkName: "" */))
const _7736e7af = () => interopDefault(import('../pages/_Palmers/Forget.vue' /* webpackChunkName: "" */))
const _0189d697 = () => interopDefault(import('../pages/_Palmers/Login.vue' /* webpackChunkName: "" */))
const _7497b236 = () => interopDefault(import('../pages/_Palmers/Register.vue' /* webpackChunkName: "" */))
const _4ad0cb2c = () => interopDefault(import('../pages/_Palmers/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _a7a276ba = () => interopDefault(import('../pages/_Palmers/PalmersStory.vue' /* webpackChunkName: "" */))
const _19df4d07 = () => interopDefault(import('../pages/_Palmers/Wishlist.vue' /* webpackChunkName: "" */))
const _0927dc3c = () => interopDefault(import('../pages/_Palmers/Cart.vue' /* webpackChunkName: "" */))
const _37b488ca = () => interopDefault(import('../pages/_Palmers/NewsLetter.vue' /* webpackChunkName: "" */))
const _2e42a9d1 = () => interopDefault(import('../pages/_Palmers/Page.vue' /* webpackChunkName: "" */))
const _5a135b5c = () => interopDefault(import('../pages/_Palmers/SSOLogin.vue' /* webpackChunkName: "" */))
const _199e48a1 = () => interopDefault(import('../pages/_Palmers/PageBuilderPreviewMode.vue' /* webpackChunkName: "" */))
const _0cc0075e = () => interopDefault(import('../pages/_Palmers/PageBuilderPreview.vue' /* webpackChunkName: "" */))
const _702315ea = () => interopDefault(import('../pages/_Palmers/Search.vue' /* webpackChunkName: "" */))
const _44e60fc0 = () => interopDefault(import('../pages/_Palmers/Category.vue' /* webpackChunkName: "pages/_Palmers/Category" */))
const _25826cf4 = () => interopDefault(import('../pages/_Palmers/Checkout/Form.js' /* webpackChunkName: "pages/_Palmers/Checkout/Form" */))
const _b82a93b8 = () => interopDefault(import('../pages/_Palmers/CheckoutChild.vue' /* webpackChunkName: "pages/_Palmers/CheckoutChild" */))
const _beb27412 = () => interopDefault(import('../pages/_Palmers/Cms.vue' /* webpackChunkName: "pages/_Palmers/Cms" */))
const _8d182b64 = () => interopDefault(import('../pages/_Palmers/Contact.vue' /* webpackChunkName: "pages/_Palmers/Contact" */))
const _2ac32144 = () => interopDefault(import('../pages/_Palmers/Faq.vue' /* webpackChunkName: "pages/_Palmers/Faq" */))
const _5d5ccccb = () => interopDefault(import('../pages/_Palmers/PalmersStorySingle.vue' /* webpackChunkName: "pages/_Palmers/PalmersStorySingle" */))
const _5e1b939d = () => interopDefault(import('../pages/_Palmers/Product.vue' /* webpackChunkName: "pages/_Palmers/Product" */))
const _2345db8c = () => interopDefault(import('../pages/_Palmers/SubCategory.vue' /* webpackChunkName: "pages/_Palmers/SubCategory" */))
const _3debf3dc = () => interopDefault(import('../pages/_Palmers/MyAccount/useSidebarLinkGroups.ts' /* webpackChunkName: "pages/_Palmers/MyAccount/useSidebarLinkGroups" */))
const _a0255872 = () => interopDefault(import('../pages/_Palmers/MyAccount/Account/AddressFormAccount.vue' /* webpackChunkName: "pages/_Palmers/MyAccount/Account/AddressFormAccount" */))
const _12456a36 = () => interopDefault(import('../pages/_Palmers/MyAccount/Account/PasswordResetForm.vue' /* webpackChunkName: "pages/_Palmers/MyAccount/Account/PasswordResetForm" */))
const _7baf5c94 = () => interopDefault(import('../pages/_Palmers/MyAccount/Account/ProfileUpdateForm.vue' /* webpackChunkName: "pages/_Palmers/MyAccount/Account/ProfileUpdateForm" */))
const _4e0c44ee = () => interopDefault(import('../pages/_Palmers/MyAccount/Account/types.ts' /* webpackChunkName: "pages/_Palmers/MyAccount/Account/types" */))
const _062b1b30 = () => interopDefault(import('../pages/_Palmers/MyAccount/Account/UpdateAdditionalInfo.vue' /* webpackChunkName: "pages/_Palmers/MyAccount/Account/UpdateAdditionalInfo" */))
const _3bba3d8a = () => interopDefault(import('../pages/_Palmers/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "pages/_Palmers/MyAccount/AddressesDetails/AddressNew" */))
const _54b65143 = () => interopDefault(import('../pages/_Palmers/MyAccount/Overview/OverviewAddresses.vue' /* webpackChunkName: "pages/_Palmers/MyAccount/Overview/OverviewAddresses" */))
const _460426a5 = () => interopDefault(import('../pages/_Palmers/MyAccount/Overview/OverviewClub.vue' /* webpackChunkName: "pages/_Palmers/MyAccount/Overview/OverviewClub" */))
const _7bc4dcca = () => interopDefault(import('../pages/_Palmers/MyAccount/OrderHistory/SingleOrder/OrderInformationAddressColumn.vue' /* webpackChunkName: "pages/_Palmers/MyAccount/OrderHistory/SingleOrder/OrderInformationAddressColumn" */))
const _d1f00300 = () => interopDefault(import('../pages/_Palmers/MyAccount/OrderHistory/SingleOrder/OrderSummaryRow.vue' /* webpackChunkName: "pages/_Palmers/MyAccount/OrderHistory/SingleOrder/OrderSummaryRow" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout/",
    component: _faa587f0,
    name: "checkout___palmers_de",
    children: [{
      path: "order-error/",
      component: _0f4daf91,
      name: "order-error___palmers_de"
    }, {
      path: "payment/",
      component: _7b138f5f,
      name: "payment___palmers_de"
    }, {
      path: "register/",
      component: _bc591bcc,
      name: "checkout-register___palmers_de"
    }, {
      path: "shipping-methods/",
      component: _544ab37d,
      name: "shipping-methods___palmers_de"
    }, {
      path: "shipping/",
      component: _2044b8b6,
      name: "shipping___palmers_de"
    }, {
      path: "thank-you/",
      component: _4d292168,
      name: "thank-you___palmers_de"
    }, {
      path: "user-account/",
      component: _c5249a8a,
      name: "user-account___palmers_de"
    }]
  }, {
    path: "/checkout/",
    component: _faa587f0,
    name: "checkout___gifts_de",
    children: [{
      path: "order-error/",
      component: _0f4daf91,
      name: "order-error___gifts_de"
    }, {
      path: "payment/",
      component: _7b138f5f,
      name: "payment___gifts_de"
    }, {
      path: "register/",
      component: _bc591bcc,
      name: "checkout-register___gifts_de"
    }, {
      path: "shipping-methods/",
      component: _544ab37d,
      name: "shipping-methods___gifts_de"
    }, {
      path: "shipping/",
      component: _2044b8b6,
      name: "shipping___gifts_de"
    }, {
      path: "thank-you/",
      component: _4d292168,
      name: "thank-you___gifts_de"
    }, {
      path: "user-account/",
      component: _c5249a8a,
      name: "user-account___gifts_de"
    }]
  }, {
    path: "/customer/",
    component: _11f018c1,
    meta: {"titleLabel":"My Account"},
    name: "customer___palmers_de",
    children: [{
      path: "account/",
      component: _3c213f0b,
      meta: {"titleLabel":"Account"},
      name: "customer-account___palmers_de"
    }, {
      path: "addresses-details/",
      component: _78ad65a2,
      meta: {"titleLabel":"Addresses"},
      name: "customer-addresses-details___palmers_de"
    }, {
      path: "faq/",
      component: _603d259f,
      meta: {"titleLabel":"FAQ"},
      name: "customer-faq___palmers_de"
    }, {
      path: "loyalty/",
      component: _7794bd86,
      meta: {"titleLabel":"Loyalty and vouchers"},
      name: "customer-loyalty___palmers_de"
    }, {
      path: "order-history/",
      component: _f0813b22,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___palmers_de"
    }, {
      path: "overview/",
      component: _6bb21c4f,
      meta: {"titleLabel":"Overview"},
      name: "customer-overview___palmers_de"
    }, {
      path: "order-history/:orderId/",
      component: _0668f5b8,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___palmers_de"
    }]
  }, {
    path: "/customer/",
    component: _11f018c1,
    meta: {"titleLabel":"My Account"},
    name: "customer___gifts_de",
    children: [{
      path: "account/",
      component: _3c213f0b,
      meta: {"titleLabel":"Account"},
      name: "customer-account___gifts_de"
    }, {
      path: "addresses-details/",
      component: _78ad65a2,
      meta: {"titleLabel":"Addresses"},
      name: "customer-addresses-details___gifts_de"
    }, {
      path: "faq/",
      component: _603d259f,
      meta: {"titleLabel":"FAQ"},
      name: "customer-faq___gifts_de"
    }, {
      path: "loyalty/",
      component: _7794bd86,
      meta: {"titleLabel":"Loyalty and vouchers"},
      name: "customer-loyalty___gifts_de"
    }, {
      path: "order-history/",
      component: _f0813b22,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___gifts_de"
    }, {
      path: "overview/",
      component: _6bb21c4f,
      meta: {"titleLabel":"Overview"},
      name: "customer-overview___gifts_de"
    }, {
      path: "order-history/:orderId/",
      component: _0668f5b8,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___gifts_de"
    }]
  }, {
    path: "/filialen/",
    component: _01d10015,
    name: "store-finder___palmers_de"
  }, {
    path: "/filialen/",
    component: _01d10015,
    name: "store-finder___gifts_de"
  }, {
    path: "/forget/",
    component: _7736e7af,
    name: "forget___palmers_de"
  }, {
    path: "/forget/",
    component: _7736e7af,
    name: "forget___gifts_de"
  }, {
    path: "/login/",
    component: _0189d697,
    props: true,
    name: "login___palmers_de"
  }, {
    path: "/login/",
    component: _0189d697,
    props: true,
    name: "login___gifts_de"
  }, {
    path: "/register/",
    component: _7497b236,
    name: "register___palmers_de"
  }, {
    path: "/register/",
    component: _7497b236,
    name: "register___gifts_de"
  }, {
    path: "/reset-password/",
    component: _4ad0cb2c,
    alias: "/customer/account/createPassword",
    name: "reset-password___palmers_de"
  }, {
    path: "/reset-password/",
    component: _4ad0cb2c,
    alias: "/customer/account/createPassword",
    name: "reset-password___gifts_de"
  }, {
    path: "/stories/",
    component: _a7a276ba,
    name: "stories___palmers_de"
  }, {
    path: "/stories/",
    component: _a7a276ba,
    name: "stories___gifts_de"
  }, {
    path: "/wishlist/",
    component: _19df4d07,
    name: "wishlist___palmers_de"
  }, {
    path: "/wishlist/",
    component: _19df4d07,
    name: "wishlist___gifts_de"
  }, {
    path: "/account/confirm/",
    component: _0189d697,
    props: {"default":true,"confirm":true},
    alias: "/customer/account/confirm",
    name: "account-confirm___palmers_de"
  }, {
    path: "/account/confirm/",
    component: _0189d697,
    props: {"default":true,"confirm":true},
    alias: "/customer/account/confirm",
    name: "account-confirm___gifts_de"
  }, {
    path: "/checkout/cart/",
    component: _0927dc3c,
    name: "cart___palmers_de"
  }, {
    path: "/checkout/cart/",
    component: _0927dc3c,
    name: "cart___gifts_de"
  }, {
    path: "/newsletter/subscribe/",
    component: _37b488ca,
    name: "newsletter___palmers_de"
  }, {
    path: "/newsletter/subscribe/",
    component: _37b488ca,
    name: "newsletter___gifts_de"
  }, {
    path: "/",
    component: _2e42a9d1,
    name: "home___palmers_de"
  }, {
    path: "/",
    component: _2e42a9d1,
    name: "home___gifts_de"
  }, {
    path: "/filialen/filter/:id/",
    component: _01d10015,
    name: "store-finder-filter___palmers_de"
  }, {
    path: "/filialen/filter/:id/",
    component: _01d10015,
    name: "store-finder-filter___gifts_de"
  }, {
    path: "/login/customer/:token/",
    component: _5a135b5c,
    name: "sso-login___palmers_de"
  }, {
    path: "/login/customer/:token/",
    component: _5a135b5c,
    name: "sso-login___gifts_de"
  }, {
    path: "/page-builder-preview-mode/token/:token/",
    component: _199e48a1,
    name: "page-builder-preview-mode___palmers_de"
  }, {
    path: "/page-builder-preview-mode/token/:token/",
    component: _199e48a1,
    name: "page-builder-preview-mode___gifts_de"
  }, {
    path: "/page-builder-preview/token/:token/",
    component: _0cc0075e,
    name: "page-builder-preview___palmers_de"
  }, {
    path: "/page-builder-preview/token/:token/",
    component: _0cc0075e,
    name: "page-builder-preview___gifts_de"
  }, {
    path: "/search/:term/",
    component: _702315ea,
    props: true,
    name: "search___palmers_de"
  }, {
    path: "/search/:term/",
    component: _702315ea,
    props: true,
    name: "search___gifts_de"
  }, {
    path: "/wishlist-share/:sharing_code/",
    component: _19df4d07,
    name: "wishlist-share___palmers_de"
  }, {
    path: "/wishlist-share/:sharing_code/",
    component: _19df4d07,
    name: "wishlist-share___gifts_de"
  }, {
    path: "/:slug+/",
    component: _2e42a9d1,
    name: "page___palmers_de"
  }, {
    path: "/:slug+/",
    component: _2e42a9d1,
    name: "page___gifts_de"
  }, {
    path: "/:Palmers?/Cart/",
    component: _0927dc3c,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Cart___palmers_de"
  }, {
    path: "/:Palmers?/Cart/",
    component: _0927dc3c,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Cart___gifts_de"
  }, {
    path: "/:Palmers?/Category/",
    component: _44e60fc0,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Category___palmers_de"
  }, {
    path: "/:Palmers?/Category/",
    component: _44e60fc0,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Category___gifts_de"
  }, {
    path: "/:Palmers?/Checkout/",
    component: _faa587f0,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Checkout___palmers_de",
    children: [{
      path: "Form/",
      component: _25826cf4,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-Form___palmers_de"
    }, {
      path: "OrderError/",
      component: _0f4daf91,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-OrderError___palmers_de"
    }, {
      path: "Payment/",
      component: _7b138f5f,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-Payment___palmers_de"
    }, {
      path: "Register/",
      component: _bc591bcc,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-Register___palmers_de"
    }, {
      path: "Shipping/",
      component: _2044b8b6,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-Shipping___palmers_de"
    }, {
      path: "ShippingMethods/",
      component: _544ab37d,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-ShippingMethods___palmers_de"
    }, {
      path: "ThankYou/",
      component: _4d292168,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-ThankYou___palmers_de"
    }, {
      path: "UserAccount/",
      component: _c5249a8a,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-UserAccount___palmers_de"
    }]
  }, {
    path: "/:Palmers?/Checkout/",
    component: _faa587f0,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Checkout___gifts_de",
    children: [{
      path: "Form/",
      component: _25826cf4,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-Form___gifts_de"
    }, {
      path: "OrderError/",
      component: _0f4daf91,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-OrderError___gifts_de"
    }, {
      path: "Payment/",
      component: _7b138f5f,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-Payment___gifts_de"
    }, {
      path: "Register/",
      component: _bc591bcc,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-Register___gifts_de"
    }, {
      path: "Shipping/",
      component: _2044b8b6,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-Shipping___gifts_de"
    }, {
      path: "ShippingMethods/",
      component: _544ab37d,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-ShippingMethods___gifts_de"
    }, {
      path: "ThankYou/",
      component: _4d292168,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-ThankYou___gifts_de"
    }, {
      path: "UserAccount/",
      component: _c5249a8a,
      pathToRegexpOptions: {"strict":true},
      name: "Palmers-Checkout-UserAccount___gifts_de"
    }]
  }, {
    path: "/:Palmers?/CheckoutChild/",
    component: _b82a93b8,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-CheckoutChild___palmers_de"
  }, {
    path: "/:Palmers?/CheckoutChild/",
    component: _b82a93b8,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-CheckoutChild___gifts_de"
  }, {
    path: "/:Palmers?/Cms/",
    component: _beb27412,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Cms___palmers_de"
  }, {
    path: "/:Palmers?/Cms/",
    component: _beb27412,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Cms___gifts_de"
  }, {
    path: "/:Palmers?/Contact/",
    component: _8d182b64,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Contact___palmers_de"
  }, {
    path: "/:Palmers?/Contact/",
    component: _8d182b64,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Contact___gifts_de"
  }, {
    path: "/:Palmers?/Faq/",
    component: _2ac32144,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Faq___palmers_de"
  }, {
    path: "/:Palmers?/Faq/",
    component: _2ac32144,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Faq___gifts_de"
  }, {
    path: "/:Palmers?/Forget/",
    component: _7736e7af,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Forget___palmers_de"
  }, {
    path: "/:Palmers?/Forget/",
    component: _7736e7af,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Forget___gifts_de"
  }, {
    path: "/:Palmers?/Login/",
    component: _0189d697,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Login___palmers_de"
  }, {
    path: "/:Palmers?/Login/",
    component: _0189d697,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Login___gifts_de"
  }, {
    path: "/:Palmers?/NewsLetter/",
    component: _37b488ca,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-NewsLetter___palmers_de"
  }, {
    path: "/:Palmers?/NewsLetter/",
    component: _37b488ca,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-NewsLetter___gifts_de"
  }, {
    path: "/:Palmers?/Page/",
    component: _2e42a9d1,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Page___palmers_de"
  }, {
    path: "/:Palmers?/Page/",
    component: _2e42a9d1,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Page___gifts_de"
  }, {
    path: "/:Palmers?/PageBuilderPreview/",
    component: _0cc0075e,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-PageBuilderPreview___palmers_de"
  }, {
    path: "/:Palmers?/PageBuilderPreview/",
    component: _0cc0075e,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-PageBuilderPreview___gifts_de"
  }, {
    path: "/:Palmers?/PageBuilderPreviewMode/",
    component: _199e48a1,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-PageBuilderPreviewMode___palmers_de"
  }, {
    path: "/:Palmers?/PageBuilderPreviewMode/",
    component: _199e48a1,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-PageBuilderPreviewMode___gifts_de"
  }, {
    path: "/:Palmers?/PalmersStory/",
    component: _a7a276ba,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-PalmersStory___palmers_de"
  }, {
    path: "/:Palmers?/PalmersStory/",
    component: _a7a276ba,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-PalmersStory___gifts_de"
  }, {
    path: "/:Palmers?/PalmersStorySingle/",
    component: _5d5ccccb,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-PalmersStorySingle___palmers_de"
  }, {
    path: "/:Palmers?/PalmersStorySingle/",
    component: _5d5ccccb,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-PalmersStorySingle___gifts_de"
  }, {
    path: "/:Palmers?/Product/",
    component: _5e1b939d,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Product___palmers_de"
  }, {
    path: "/:Palmers?/Product/",
    component: _5e1b939d,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Product___gifts_de"
  }, {
    path: "/:Palmers?/Register/",
    component: _7497b236,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Register___palmers_de"
  }, {
    path: "/:Palmers?/Register/",
    component: _7497b236,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Register___gifts_de"
  }, {
    path: "/:Palmers?/Search/",
    component: _702315ea,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Search___palmers_de"
  }, {
    path: "/:Palmers?/Search/",
    component: _702315ea,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Search___gifts_de"
  }, {
    path: "/:Palmers?/SSOLogin/",
    component: _5a135b5c,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-SSOLogin___palmers_de"
  }, {
    path: "/:Palmers?/SSOLogin/",
    component: _5a135b5c,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-SSOLogin___gifts_de"
  }, {
    path: "/:Palmers?/StoreFinder/",
    component: _01d10015,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-StoreFinder___palmers_de"
  }, {
    path: "/:Palmers?/StoreFinder/",
    component: _01d10015,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-StoreFinder___gifts_de"
  }, {
    path: "/:Palmers?/SubCategory/",
    component: _2345db8c,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-SubCategory___palmers_de"
  }, {
    path: "/:Palmers?/SubCategory/",
    component: _2345db8c,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-SubCategory___gifts_de"
  }, {
    path: "/:Palmers?/Wishlist/",
    component: _19df4d07,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Wishlist___palmers_de"
  }, {
    path: "/:Palmers?/Wishlist/",
    component: _19df4d07,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-Wishlist___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/MyAccount/",
    component: _11f018c1,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-MyAccount___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/MyAccount/",
    component: _11f018c1,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-MyAccount___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/ResetPassword/",
    component: _4ad0cb2c,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-ResetPassword___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/ResetPassword/",
    component: _4ad0cb2c,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-ResetPassword___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/useSidebarLinkGroups/",
    component: _3debf3dc,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-useSidebarLinkGroups___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/useSidebarLinkGroups/",
    component: _3debf3dc,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-useSidebarLinkGroups___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/Account/",
    component: _3c213f0b,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-Account___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/Account/",
    component: _3c213f0b,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-Account___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/AddressFormAccount/",
    component: _a0255872,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-AddressFormAccount___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/AddressFormAccount/",
    component: _a0255872,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-AddressFormAccount___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/PasswordResetForm/",
    component: _12456a36,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-PasswordResetForm___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/PasswordResetForm/",
    component: _12456a36,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-PasswordResetForm___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/ProfileUpdateForm/",
    component: _7baf5c94,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-ProfileUpdateForm___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/ProfileUpdateForm/",
    component: _7baf5c94,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-ProfileUpdateForm___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/types/",
    component: _4e0c44ee,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-types___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/types/",
    component: _4e0c44ee,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-types___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/UpdateAdditionalInfo/",
    component: _062b1b30,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-UpdateAdditionalInfo___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/Account/UpdateAdditionalInfo/",
    component: _062b1b30,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Account-UpdateAdditionalInfo___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/AddressesDetails/AddressesDetails/",
    component: _78ad65a2,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-AddressesDetails-AddressesDetails___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/AddressesDetails/AddressesDetails/",
    component: _78ad65a2,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-AddressesDetails-AddressesDetails___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/AddressesDetails/AddressNew/",
    component: _3bba3d8a,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-AddressesDetails-AddressNew___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/AddressesDetails/AddressNew/",
    component: _3bba3d8a,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-AddressesDetails-AddressNew___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/Faq/CustomerFaq/",
    component: _603d259f,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Faq-CustomerFaq___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/Faq/CustomerFaq/",
    component: _603d259f,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Faq-CustomerFaq___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/Loyalty/Loyalty/",
    component: _7794bd86,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Loyalty-Loyalty___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/Loyalty/Loyalty/",
    component: _7794bd86,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Loyalty-Loyalty___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/OrderHistory/OrderHistory/",
    component: _f0813b22,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-OrderHistory-OrderHistory___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/OrderHistory/OrderHistory/",
    component: _f0813b22,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-OrderHistory-OrderHistory___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/Overview/Overview/",
    component: _6bb21c4f,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Overview-Overview___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/Overview/Overview/",
    component: _6bb21c4f,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Overview-Overview___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/Overview/OverviewAddresses/",
    component: _54b65143,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Overview-OverviewAddresses___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/Overview/OverviewAddresses/",
    component: _54b65143,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Overview-OverviewAddresses___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/Overview/OverviewClub/",
    component: _460426a5,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Overview-OverviewClub___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/Overview/OverviewClub/",
    component: _460426a5,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-Overview-OverviewClub___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/OrderHistory/SingleOrder/OrderInformationAddressColumn/",
    component: _7bc4dcca,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-OrderHistory-SingleOrder-OrderInformationAddressColumn___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/OrderHistory/SingleOrder/OrderInformationAddressColumn/",
    component: _7bc4dcca,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-OrderHistory-SingleOrder-OrderInformationAddressColumn___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/OrderHistory/SingleOrder/OrderSummaryRow/",
    component: _d1f00300,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-OrderHistory-SingleOrder-OrderSummaryRow___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/OrderHistory/SingleOrder/OrderSummaryRow/",
    component: _d1f00300,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-OrderHistory-SingleOrder-OrderSummaryRow___gifts_de"
  }, {
    path: "/:Palmers?/MyAccount/OrderHistory/SingleOrder/SingleOrder/",
    component: _0668f5b8,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-OrderHistory-SingleOrder-SingleOrder___palmers_de"
  }, {
    path: "/:Palmers?/MyAccount/OrderHistory/SingleOrder/SingleOrder/",
    component: _0668f5b8,
    pathToRegexpOptions: {"strict":true},
    name: "Palmers-MyAccount-OrderHistory-SingleOrder-SingleOrder___gifts_de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
